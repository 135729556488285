/* eslint-disable no-unused-vars */
import { NavigationGuardNext, Route } from "vue-router";
import Microsoft from "@/plugins/microsoft";

export function beforeEach(
  to: Route,
  from: Route,
  next: NavigationGuardNext
): void {
  return next();
}

export function beforeResolve(
  to: Route,
  from: Route,
  next: NavigationGuardNext
): void {
  const loggedIn = Microsoft.loggedIn();

  console.log(
    `%c Auth%c|%cstate:%c${loggedIn ? "🔒" : "🔓"}%c`,
    "background:#654d24 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff",
    "background:#616161 ; padding: 1px; border-radius: 0 0 0 0;  color: #fff",
    "background:#562593 ; padding: 1px; border-radius: 0 0 0 0;  color: #fff",
    `background:${
      loggedIn ? "#00FF00" : "#FF0000"
    } ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff`,
    "background:transparent"
  );

  if (to.meta.authentication === "required" && loggedIn === false) {
    return next({ name: "oauth-login" });
  }

  return next();
}

export function afterEach(to: Route, from: Route): void {
  console.log(
    `Navigated from %c ${from.name}%c@%c{ path: '${from.path}' auth: ${
      from.meta.authentication === "required" ? "🔒" : "🔓"
    }} %c to %c ${to.name}%c@%c{ path: '${to.path}' auth: ${
      to.meta.authentication === "required" ? "🔒" : "🔓"
    }} %c`,
    "background:#654d24 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff",
    "background:#616161 ; padding: 1px; border-radius: 0 0 0 0;  color: #fff",
    "background:#562593 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff",
    "background:transparent",
    "background:#654d24 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff",
    "background:#616161 ; padding: 1px; border-radius: 0 0 0 0;  color: #fff",
    "background:#562593 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff",
    "background:transparent"
  );
  return;
}
