declare const BASE_URL: string;
declare const MICROSOFT_CLIENT_ID: string;
declare const MICROSOFT_AUTHORITY: string;
declare const MICROSOFT_LOGIN_SCOPES: string[];
declare const MICROSOFT_TOKEN_SCOPES: string[];

export class Microsoft {
  public redirectUri = `${BASE_URL}/oauth/login`;
  public clientId: string = MICROSOFT_CLIENT_ID;
  public authority: string = MICROSOFT_AUTHORITY;
  public loginScopes: string[] = MICROSOFT_LOGIN_SCOPES;
  public tokenScopes: string[] = MICROSOFT_TOKEN_SCOPES;
}

export default new Microsoft();
