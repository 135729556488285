




import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup(): Record<string, unknown> {
    return {};
  },
});
