import Vue, { PluginObject } from "vue";
import general, { General } from "@/configs/general";
import microsoft, { Microsoft } from "@/configs/microsoft";

class ConfigPluginOptions {}

export class Config implements PluginObject<Partial<ConfigPluginOptions>> {
  public install(
    vue: typeof Vue,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options?: Partial<ConfigPluginOptions>
  ): void {
    Object.defineProperties(vue.prototype, {
      $config: {
        get(): Config {
          return config;
        },
      },
    });
  }

  public General: General = general;
  public Microsoft: Microsoft = microsoft;
}

const config = new Config();

Vue.use(config);

export default config;
