import Vue, { PluginObject } from "vue";
import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  AuthenticationProvider,
  AuthenticationProviderOptions,
  Client,
  MSALAuthenticationProviderOptions,
} from "@microsoft/microsoft-graph-client";
import { MailFolder, Message } from "@microsoft/microsoft-graph-types";
import Config from "./config";

export class PublicMSALAuthenticationProvider
  implements AuthenticationProvider {
  private application: PublicClientApplication;
  private options: MSALAuthenticationProviderOptions;

  public constructor(
    application: PublicClientApplication,
    options: MSALAuthenticationProviderOptions
  ) {
    this.application = application;
    this.options = options;
  }
  /**
   * This method will get called before every request to the msgraph server
   * This should return a Promise that resolves to an accessToken (in case of success) or rejects with error (in case of failure)
   * Basically this method will contain the implementation for getting and refreshing accessTokens
   */
  public async getAccessToken(
    authenticationProviderOptions?: AuthenticationProviderOptions
  ): Promise<string> {
    const options = authenticationProviderOptions as MSALAuthenticationProviderOptions;
    const scopes = options?.scopes ?? this.options?.scopes;

    try {
      const accounts = this.application.getAllAccounts();
      if (!!accounts && accounts.length > 0) {
        return this.application
          .acquireTokenSilent({ scopes, account: accounts[0] })
          .then((result: AuthenticationResult) => result.accessToken);
      } else {
        return this.application
          .acquireTokenPopup({ scopes })
          .then((result: AuthenticationResult) => result.accessToken);
      }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        return this.application
          .acquireTokenPopup({ scopes })
          .then((result: AuthenticationResult) => result.accessToken);
      } else {
        throw error;
      }
    }
  }
}

class MicrosoftPluginOptions {}

export interface CollectionResponse<T> {
  value: T[];
  "@odata.context": string;
  "@odata.nextLink"?: string;
}

export class MicrosoftQuery {
  public $count: boolean | null = null;
  public $expand: string | null = null;
  public $filter: string | null = null;
  public $format: string | null = null;
  public $orderby: string | null = null;
  public $search: string | null = null;
  public $select: string[] | null = null;
  public $skip: number | null = null;
  public $top: number | null = null;

  public count($count: boolean): this {
    this.$count = $count;
    return this;
  }

  public expand($expand: string): this {
    this.$expand = $expand;
    return this;
  }

  public filter($filter: string): this {
    this.$filter = $filter;
    return this;
  }

  public format($format: string): this {
    this.$format = $format;
    return this;
  }

  public orderby($orderby: string): this {
    this.$orderby = $orderby;
    return this;
  }

  public search($search: string): this {
    this.$search = $search;
    return this;
  }

  public select($select: string[]): this {
    this.$select = $select;
    return this;
  }

  public skip($skip: number): this {
    this.$skip = $skip;
    return this;
  }

  public top($top: number): this {
    this.$top = $top;
    return this;
  }

  public toString(): string {
    const params = new URLSearchParams();

    if (this.$count !== null)
      params.append("$count", this.$count ? "true" : "false");

    if (this.$expand !== null) params.append("$expand", this.$expand);

    if (this.$filter !== null) params.append("$filter", this.$filter);

    if (this.$format !== null) params.append("$format", this.$format);

    if (this.$orderby !== null) params.append("$orderby", this.$orderby);

    if (this.$search !== null) params.append("$search", this.$search);

    if (this.$select !== null) params.append("$select", this.$select.join(","));

    if (this.$skip !== null) params.append("$skip", this.$skip.toString());

    if (this.$top !== null) params.append("$top", this.$top.toString());

    return "?" + params.toString();
  }
}

export class Microsoft
  implements PluginObject<Partial<MicrosoftPluginOptions>> {
  private application: PublicClientApplication;
  private options: MSALAuthenticationProviderOptions;
  private provider: PublicMSALAuthenticationProvider;
  private client: Client;

  public constructor() {
    this.application = new PublicClientApplication({
      auth: {
        clientId: Config.Microsoft.clientId,
        redirectUri: Config.Microsoft.redirectUri,
      },
    });
    this.options = new MSALAuthenticationProviderOptions(
      Config.Microsoft.tokenScopes
    );
    this.provider = new PublicMSALAuthenticationProvider(
      this.application,
      this.options
    );
    this.client = Client.initWithMiddleware({
      authProvider: this.provider,
    });
  }

  public install(
    vue: typeof Vue,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options?: Partial<MicrosoftPluginOptions>
  ): void {
    Object.defineProperties(vue.prototype, {
      $microsoft: {
        get(): Microsoft {
          return microsoft;
        },
      },
    });
  }

  public async doPopupLogin(): Promise<AuthenticationResult> {
    return this.application.loginPopup({
      scopes: this.options.scopes,
    });
  }

  public async doRedirectLogin(): Promise<void> {
    return this.application.loginRedirect({
      scopes: this.options.scopes,
    });
  }

  public async doLogout(): Promise<void> {
    const accounts = this.application.getAllAccounts();

    return this.application.logout({
      account: accounts[0],
      postLogoutRedirectUri: Config.Microsoft.redirectUri,
    });
  }

  public async handleRedirectLoginResponse(
    hash?: string | undefined
  ): Promise<AuthenticationResult | null> {
    return this.application.handleRedirectPromise(hash);
  }

  public loggedIn(): boolean {
    const accounts = this.application.getAllAccounts();
    return !!accounts && accounts.length > 0;
  }

  public async clientApiGet(
    path: string,
    query: MicrosoftQuery | string | null = null
  ): Promise<unknown | CollectionResponse<unknown>> {
    return this.client.api(path + (query ?? "")).get();
  }

  public async getMailFolders(
    query: MicrosoftQuery | string | null = null
  ): Promise<CollectionResponse<MailFolder>> {
    return this.clientApiGet("/me/mailFolders", query) as Promise<
      CollectionResponse<MailFolder>
    >;
  }

  public async getMailFolder(
    folder = "inbox",
    query: MicrosoftQuery | string | null = null
  ): Promise<MailFolder> {
    return this.clientApiGet(
      `/me/mailFolders/${folder}`,
      query
    ) as Promise<MailFolder>;
  }

  public async getMailFromFolder(
    folder = "inbox",
    query: MicrosoftQuery | string | null = null
  ): Promise<CollectionResponse<Message>> {
    return this.clientApiGet(
      `/me/mailFolders/${folder}/messages`,
      query
    ) as Promise<CollectionResponse<Message>>;
  }
}

const microsoft = new Microsoft();

export default microsoft;
