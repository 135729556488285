import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { createRouterLayout } from "vue-router-layout";
import autoRoutes from "vue-auto-routing";
import { beforeEach, beforeResolve, afterEach } from "./guards";

console.group(
  "%c Router%c|%cconfiguration %c",
  "background:#654d24 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff",
  "background:#616161 ; padding: 1px; border-radius: 0 0 0 0;  color: #fff",
  "background:#562593 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff",
  "background:transparent"
);

console.log(
  "%c Router%c|%cautomatic-routes %c ↲ %c",
  "background:#654d24 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff",
  "background:#616161 ; padding: 1px; border-radius: 0 0 0 0;  color: #fff",
  "background:#562593 ; padding: 1px; border-radius: 0 0 0 0;  color: #fff",
  "background:#616161 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff",
  "background:transparent",
  autoRoutes
);

Vue.use(VueRouter);

const RouterLayout = createRouterLayout(
  (layout) =>
    import(/* webpackChunkName: "layouts" */ `@/layouts/${layout}.vue`)
);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: RouterLayout,
    children: autoRoutes,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
  console.group(
    "%c Router%c|%cbeforeEach %c",
    "background:#654d24 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff",
    "background:#616161 ; padding: 1px; border-radius: 0 0 0 0;  color: #fff",
    "background:#562593 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff",
    "background:transparent"
  );
  beforeEach(to, from, next);
  console.groupEnd();
});

router.afterEach((to: Route, from: Route) => {
  console.group(
    "%c Router%c|%cafterEach %c",
    "background:#654d24 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff",
    "background:#616161 ; padding: 1px; border-radius: 0 0 0 0;  color: #fff",
    "background:#562593 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff",
    "background:transparent"
  );
  afterEach(to, from);
  console.groupEnd();
});

router.beforeResolve((to: Route, from: Route, next: NavigationGuardNext) => {
  console.group(
    "%c Router%c|%cbeforeResolve %c",
    "background:#654d24 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff",
    "background:#616161 ; padding: 1px; border-radius: 0 0 0 0;  color: #fff",
    "background:#562593 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff",
    "background:transparent"
  );
  beforeResolve(to, from, next);
  console.groupEnd();
});

console.groupEnd();

export default router;
