import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";

import "./plugins/composition";
import "./plugins/config";
import microsoft from "./plugins/microsoft";
import { createApp, h } from "@vue/composition-api";

Vue.config.productionTip = false;

Vue.config.ignoredElements = [
  ...Vue.config.ignoredElements,
  "container",
  "main",
  "row",
  "column",
];

const app = createApp({
  router,
  store,
  vuetify,
  render: () => h(App),
});

app.use(microsoft);

app.mount("#app");
